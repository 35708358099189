import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  Book as BookIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  History as HistoryIcon,
  Assignment as AssignmentIcon  
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";


// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

var structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },  
  {
    id: 1,
    label: "ຝາກເງິີນເຂົ້າກະເປົາ",
    link: "/app/cashin",
    icon: <ArrowDownwardIcon />,
  },
  {
    id: 2,
    label: "ຖອກເງິນອອກກະເປົາ",
    link: "/app/cashout",
    icon: <ArrowUpwardIcon />,
  },
  {
    id: 3,
    label: "ປະຫວັດເຮັດທູລະກຳ",
    link: "/app/history",
    icon: <HistoryIcon />,
  },
  { id: 4, type: "divider" },
  {
    id: 5,
    label: "ໂອນເງິນເຂົ້າກະເປົາ",
    link: "/app/floatout",
    icon: <AssignmentIcon />,
  },  
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "ກ່ຽວກັບ", link: "https://mmoney.la", icon: <LibraryIcon /> },
  { id: 7, label: "ຄູ່ມືນຳໃຊ້", link: "https://mmoney.la/agentdoc", icon: <BookIcon /> },

  
];



var structureZero = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },  
  
  {
    id: 3,
    label: "History",
    link: "/app/history",
    icon: <HistoryIcon />,
  },  
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "about", link: "https://mmoney.la", icon: <LibraryIcon /> },

  
];
///const Users = JSON.parse(localStorage.getItem('users'));
function Sidebar({ location,data }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var Users = data;
  
  console.log('sidebar',Users);
  // local
  var [isPermanent, setPermanent] = useState(true);
  var [Listmenu, setListmenu] = useState(null);

  useEffect(function() {

    setListmenu(structure);
    
  //  if(Users.wallet_amt === 0){
  //   setListmenu(structureZero);
  //  }
  //  else{
  //   setListmenu(structure);
  //  }
    
   // structure.push(od);
   
    
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  },[Users.wallet_amt]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIcon),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {Listmenu != null && Listmenu.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);

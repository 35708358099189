import React, { useState } from 'react';
import { useUserDispatch, loginUser } from "../../context/UserContext";

import {
  Grid,
  Typography,
  Container,
  Button,
  Checkbox,
  FormControlLabel, 
  TextField,
  Box,
  Link
} from "@material-ui/core";
import useStyles from "./styles";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import logo from "./logo.png";


function Copyright(props) {

    // global
  

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function Login(props) {

  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var classes = useStyles();
  
 

  return (
    
      <Container component="main" maxWidth="xs">
        <div className={classes.cantainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        </div>
       
          
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="PhoneNumber"
              label="Phone Number"
              name="PhoneNumber"
              autoComplete="PhoneNumber"
              autoFocus
              value={loginValue}
              onChange={e => setLoginValue(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
            />
            <br/>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
           
              fullWidth
              style={{height:'50px'}}
              variant="contained"
              sx={{ mt: 3, mb: 3 }}
              onClick={() =>
                loginUser(
                  userDispatch,
                  loginValue,
                  passwordValue,
                  props.history,
                  setIsLoading,
                  setError,
                )
              }
            >
              Sign In
            </Button>
       
            <Grid container >
              <Grid item xs>
                <Link href="#forgot"  variant="body2">
                  <br/>
                  Change password?
                </Link>
              </Grid>
             
            </Grid>
          </Box>
        <br/>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
   
  );
}


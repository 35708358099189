import React , {useState,useEffect} from "react";
import { Grid,TextField ,Button,TableHead,TableRow,TableCell,TableBody, Table,LinearProgress ,Paper,TableContainer,Modal,Backdrop,Fade} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from "react-toastify";



// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { RestaurantRounded } from "@material-ui/icons";



const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #A1A0A0 ',
    borderRadius : 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));


export default function CashinPage(props) {
  var classes = useStyles(); 
  var css =  useStyles2(); 
  const Users = props;
  var userDispatch = useUserDispatch();
  const[historyCashin,sethistoryCasin] = useState(null);
  var [checkAmount,setCheckAmount] = useState(true);
  var [reload, setreload] = useState(false);
  var [otpValue, setotpValue] = useState();  
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [msisdnValue, setmsisdnValue] = useState("");
  var [amountValue, setamountValue] = useState();
  // var [activeTabId, setActiveTabId] = useState(0);
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setreload(false);
  };

  
  useEffect(() => {
    // Good!
    

    const HistoryCashIn = async () =>{
      const data = {
        "userid": Users.data.userid,
        "service":  "CASHIN",
        "key" : Users.data.key               
                  
      }     
 
    const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
      };
      await fetch('/PostSale_Transaction_V2', requestOptions)
      .then((response) =>  response.json())
      .then((responsejson) =>{
       
        const result =responsejson;        
       
        if(result.resultCode=="200")
        {

        
          
                        
              
            sethistoryCasin(result.result);
            
            //console.log(result.result);
              
      
       
      }
      
  
      });
      
      
}
HistoryCashIn();

  }, [reload]);

 

  function CashIn() {
 

    if(msisdnValue=="" || amountValue ==0){
      toast.error("Please input PhoneNumber or Amount.");
      return 0;
    }

    if(amountValue%1000){
      toast.error("ກະລູນາປ້ອນຈຳນວນເງິນໃຫ້ເປັນຈຳນວນຖວ້ນ.");
      return 0;
    }



    setError(false);
    setIsLoading(true);
    
    
    
    const d = new Date(); 
    const val = Math.floor(100000 + Math.random() * 900000);
    let dates = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear().toString();
    const TranID = "A" + year + month + dates + d.getHours().toString() + d.getSeconds().toString() + val.toString();
    const data = {
                "userid": Users.data.userid,
                "transID":  TranID,
                "toAccountRef": msisdnValue,
                "transAmount": amountValue,
                "transRemark": Users.data.Provider,                
                "key" : Users.data.key
              }
    
              
    
         
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    
     fetch('/RequestCashIn_V2', requestOptions)
      .then((response) => response.json())
      .then((responsejson) =>{
       
        const result =responsejson;
        
        if(result.resultCode=="200"){
          handleOpen();
          }
          else if(result.resultCode=="202"){

            signOut(userDispatch, props.history)
          }
          else{
      
          // console.log(JSON.stringify(result));
          toast.error(result.resultDes);
          
          // dispatch({ type: "LOGIN_FAILURE" });
            setError(true);
            setIsLoading(false);
          }    
  
      });
      
  
    
  }


  function OTP() {
    //setError(false);
   // setIsLoading(true);
   document.getElementById('btnSubmit').style.display = "none";
    const data = {
      "userid": Users.data.userid,
      "sms_otp":    otpValue,
      "key" : Users.data.key
    }
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    
     fetch('/PostCashIn_V2', requestOptions)
      .then((response) => response.json())
      .then((responsejson) =>{
       
        const result =responsejson;
        console.log('return',result)
        if(result.resultCode=="200"){
                                 
         
          document.getElementById('btnSubmit').style.display = "block";

            setError(null)
            setIsLoading(false)
           // dispatch({ type: 'LOGIN_SUCCESS' })
         
            toast.success("Operation Success");
        
            //setamountValue(0);
            setreload(true);
           //alert('Success.')
       
           handleClose();   
    
        }

       
       
        else{
    
          document.getElementById('btnSubmit').style.display = "block";
        
          setError(null)
          setIsLoading(false)
          handleClose();   
         // dispatch({ type: 'LOGIN_SUCCESS' })
       
          toast.error(result.resultDes);
         
          
        }    
  
      });
      
  
    
  }


 
  return (
    <>
     
      
      <ToastContainer
         
        position="top-right"      
        autoClose={5000}
       
        
        />
      
      

        <Grid item xs={12} md={12}>
        
         

          
        </Grid>
        <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <Widget title="Cash In" disableWidgetMenu>
          <form className={classes.root} noValidate autoComplete="off">
          <div>       
            <TextField
            id="outlined-basic" label="PhoneNumber" onChange={e => setmsisdnValue(e.target.value)} variant="outlined"
              type="text"    
              required        
            
            />       
          </div>
          <br/>
         <div>
        <TextField
          required         
          type="number"    
          id="outlined-basic" label="Amount" value={amountValue} onChange={e => {  setamountValue(e.target.value); if(e.target.value!=="" && e.target.value>=10000 && e.target.value<=5000000 && (e.target.value % 1000)===0) {setCheckAmount(false);} else{setCheckAmount(true);} }} variant="outlined"
         
         
         
        />
       
      </div>
      <br/>
      <div>
      <Button style={{width:'220px'}}  onClick={CashIn} disabled={checkAmount} variant="contained" color="primary" >
        submit 
      </Button>
      {/*
      <Button style={{width:'220px'}} onClick={test} variant="contained" color="primary" >
       test 
     </Button>
     */
      }
      </div>
        </form>
        
          </Widget>        
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Amount" disableWidgetMenu>
          <div style={{textAlign:'center', paddingTop:10}}>
            <h1 style={{fontSize:80}}>{amountValue===undefined ? amountValue : amountValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ₭</h1>
            </div>
          </Widget>
        </Grid>
        </Grid>
        { isLoading == true ? <LinearProgress /> :  <br/>}
       
        <Grid item xs={12} md={12}>
          
        
        <Grid item xs={12}>
         
          <TableContainer component={Paper}>
            <Table className={css.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                <TableCell><b>TranID</b></TableCell>
                <TableCell><b>Phone</b></TableCell>
                <TableCell><b>Amount</b></TableCell>
                <TableCell><b>Date</b></TableCell>
                <TableCell><b>Fee</b></TableCell>
                <TableCell><b>Income</b></TableCell>
                <TableCell><b>Status</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyCashin != undefined && historyCashin.map(item => (
                  <TableRow key={item.TranID}>
                    <TableCell className="pl-3 fw-normal">{item.TranID}</TableCell>
                    <TableCell>{item.Msisdn}</TableCell>
                    <TableCell>{item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                    <TableCell>{item.created}</TableCell>
                    <TableCell>{item.Fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                    <TableCell>{item.Income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                    <TableCell>{item.resultDesc}</TableCell>
                    
                  </TableRow>
                )) 
                }
           
              </TableBody>
          </Table>
        </TableContainer>
       

        



        </Grid>

        

      </Grid>
      <div>
     
     <Modal
       aria-labelledby="transition-modal-title"
       aria-describedby="transition-modal-description"
       className={css.modal}
       open={open}
       onClose={handleClose}
       closeAfterTransition
       BackdropComponent={Backdrop}
       BackdropProps={{
         timeout: 500,
       }}
     >
       <Fade in={open}>
         <div className={css.paper}>
         <h3 id="transition-modal-title">Please input  OTP of Customer</h3>
           <div>       
           <TextField
          
           id="outlined-basic" label="OTP" onChange={e => setotpValue(e.target.value)} variant="outlined"
             type="number"    
             required        
           
           />       
         </div>
         <br/>
         <Button id="btnSubmit" style={{width:'220px'}} onClick={OTP} variant="contained" color="primary" >
       submit 
     </Button>

    
         </div>
       </Fade>
     </Modal>
   </div>
       
    </>
  );



}





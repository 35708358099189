import React from "react";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      //throw new Error(`Unhandled action type: ${action.type}`);
      alert(action.result)
      return 0
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('id_token'),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
 
  
  const data =  { "usercode": login,  "password": password };
       
  //console.log(data);
  //console.log(data);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  
   fetch('/PostLogin_V2', requestOptions)
    .then((response) => response.json())
    .then((responsejson) =>{
     
      const result = responsejson;
     // console.log('return',result)
      if(result.resultCode=="200"){
          //console.log(JSON.stringify(result));
          localStorage.setItem('id_token', 1)
          localStorage.setItem('users', JSON.stringify(result))
          setError(null)
          setIsLoading(false)
          dispatch({ type: 'LOGIN_SUCCESS' })
          history.push('/app/dashboard');
        /*
          history.push({
            pathname: '/app/dashboard',
            search: 'null',
            state: result 
          });
          */
         
          
  
      }
      else{
  
      //  console.log(JSON.stringify(result));
        dispatch({ type: "LOGIN_FAILURE" , result :result.resultDes });
        setError(true);
        setIsLoading(false);
      }    

    });


  
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem('users');
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}


import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Cashin from "../../pages/cashin";
import Cashout from "../../pages/cashout";
import History from "../../pages/history";
import ChangePassword from "../../pages/changePassword";
import FloatOut from "../../pages/floatout";



// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();
  const Users = JSON.parse(localStorage.getItem('users'));
  //console.log('layout',props);
  // global

  //console.log('Layout',UserDetail);

  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} data={Users}/>
          <Sidebar data={Users}/>
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={() => <Dashboard data={Users} />} /> 
              <Route path="/app/cashin"  component={() => <Cashin data={Users} />}/>
              <Route path="/app/cashout" component={() => <Cashout data={Users} />}/>
              <Route path="/app/history" component={() => <History data={Users} />} />
              <Route path="/app/floatout" component={() => <FloatOut data={Users} />} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route path="/app/changepassword" component={() => <ChangePassword data={Users} />}/> 
              {/* <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} /> */}
            </Switch>
            
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
